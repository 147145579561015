import {useQuery} from "react-query";
import axios from "axios";
import {useSnackbar} from "notistack";

const apiUrl = "api/power-station/instant-data";

const fetchPowerStationInstantData = async (powerStationId: number) => {
    try {
        const response = await axios.get(`${apiUrl}/${powerStationId}`);

        return {
            data: response.data.data,
            status: response.status
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const FindInstantDataByPowerStationReactQuery = (powerStationId:number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useQuery({
        queryKey: ['power-station-instant-data', powerStationId],
        queryFn: () => fetchPowerStationInstantData(powerStationId),
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar dados de geração de energia: ${error.message}`, { variant: "error" });
        },
        enabled: powerStationId !== undefined
    })
};