import {FunctionComponent, ReactElement, useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import {ACard, Skeleton,ProgressCard} from "@atiautomacao/ati-ui-library";
import {FindInstantDataByPowerStationReactQuery} from "./InstantDataReactQuery";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {useAppSelector} from "../../../../Config/Hooks";
import { Container } from "../../../../Shared/Components/AlarmTotalizers/AlarmTotalizers.theme";

const InstantData : FunctionComponent<{ powerPlant: any; }> = ({powerPlant}) : ReactElement => {
    const {data, isLoading} = FindInstantDataByPowerStationReactQuery(powerPlant.id)
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const [energy, setEnergy] = useState<number>(0)
    const [maxEnergy, setMaxEnergy] = useState<number>(0)
    const [irradiance, setIrradiance] = useState<number>(0)
    const [maxIrradiance, setMaxIrradiance] = useState<number>(0)
    const [meteorologicalStation, setMeteorologicalStation] = useState<any[]>([])

    let targetNames = [
        {name: "Temperatura do Módulo", maxValue: 80, unit: "ºC", value: "-", barInterval: {min: {value: 26, color: "#FFD700"}, mid: {value: 53, color:"#a4f657"}, max: {value: 80, color: "#FF4500"}}},
        {name: "Umidade do Ar", maxValue: 100, unit: "%", value: "-",  barInterval: {min: {value: 0, color:"#a4f657"}, mid: {value: 0, color:"#a4f657"}, max: {value: 0, color:"#a4f657"}}},
        {name: "Velocidade do Vento", maxValue: 14, unit: "m/s", value: "-", barInterval: {min: {value: truncateNumber(Number(14/3),2), color: "#a4f657"}, mid: {value: 11, color: "#FFD700"}, max: {value: 14, color: "#FF4500"}}},
        {name: "Temperatura do Ar", maxValue: 80, unit: "ºC", value: "-", barInterval: {min: {value: 26, color: "#a4f657"}, mid: {value: 53, color:"#FFD700"}, max: {value: 80, color: "#FF4500"}}}
    ];

    useEffect(() => {
        if(data?.status === 200){
            if(data.data.expectedEnergy !== null && data.data.activePower !== null){
                setEnergy(truncateNumber(data.data.activePower,2))
                setMaxEnergy(truncateNumber(data.data.expectedEnergy,2))
            }
            if(data.data.expectedIrradiance !== null && data.data.irradiance !== null){
                setIrradiance(truncateNumber(data.data.irradiance,2))
                setMaxIrradiance(truncateNumber(data.data.expectedIrradiance,2))
            }
            const filteredStations = data.data.meteorologicalStation.filter((station: any) =>
                targetNames.some(target => target.name === station.name)
            );

            const stationsWithMaxValue = filteredStations.map((station:any) => {
                const target = targetNames.find(target => target.name === station.name);
                let shortName
                
                if(target?.name !== undefined){
                    if(target.name.startsWith("Temperatura")){
                        shortName = target.name.replace("Temperatura", "Temp.");
                    }else if(target.name.startsWith("Umidade")){
                        shortName = target.name.replace("Umidade", "Umid.");
                    }
                } 
                return {
                    ...station,
                    name:  shortName ?? target?.name,
                    maxValue: target ? target.maxValue : undefined, 
                    unit: target ? target.unit : undefined, 
                    barInterval: target ? target.barInterval : undefined
                    
                };
            });
            setMeteorologicalStation(stationsWithMaxValue.length > 0 ? stationsWithMaxValue : targetNames)
        }else{
            targetNames = [
                {name: "Temp. do Módulo", maxValue: 80, unit: "ºC", value: "-", barInterval: {min: {value: 26, color: "#FFD700"}, mid: {value: 53, color:"#a4f657"}, max: {value: 80, color: "#FF4500"}}},
                {name: "Umid. do Ar", maxValue: 100, unit: "%", value: "-", barInterval:  {min: {value: 0, color:"#a4f657"}, mid: {value: 0, color:"#a4f657"}, max: {value: 0, color:"#a4f657"}}},
                {name: "Velocidade do Vento", maxValue: 14, unit: "m/s", value: "-", barInterval: {min: {value: truncateNumber(Number(14/3),2), color: "#a4f657"}, mid: {value: 11, color: "#FFD700"}, max: {value: 14, color: "#FF4500"}}},
                {name: "Temp. do Ar", maxValue: 80, unit: "ºC", value: "-", barInterval: {min: {value: 26, color: "#a4f657"}, mid: {value: 53, color:"#FFD700"}, max: {value: 80, color: "#FF4500"}}}
            ]
            setMeteorologicalStation(targetNames)
        }
    }, [data]);


    if (isLoading) {
        return (
            <Box style={{paddingBottom: 10}}>
                <ACard
                    title={"Dados instantâneos"}
                >
                    <Container data-testid="loading-skeleton">
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            gap: 0.5, 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            ml: {lg: openSubMenu ? 8 : 0, 
                            md: openSubMenu ? 3 : 5, xl: 0}, 
                            mr: 2, 
                            mb: 0, 
                            mt: -1 
                            }}>
                            <Grid 
                                container spacing={{ xs: 1, md: 2 }} 
                                columns={{ xs: 4, sm: 12, md: 12, lg: openSubMenu ? 6 : 12, xl: 12 }} 
                                justifyContent="center" 
                                alignItems="center"
                            >
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <Grid item xs={2} sm={4} md={openSubMenu ? 6 : 4} lg={2} xl={4} key={index.valueOf()}>
                                        <Skeleton key={index.valueOf()} animation="wave" height={100} variant="rectangular" width={220} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Container>
                </ACard>
            </Box>
        );
    }

    return (
        <ACard title={'Dados instantâneos'}>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 0.5, 
                alignItems: 'center', 
                justifyContent: 'center', 
                ml: {lg: openSubMenu ? 8 : 0, 
                md: openSubMenu ? 3 : 5, xl: 0}, 
                mr: 2, 
                mb: 0, 
                mt: -1 
                }}>
                <Grid 
                    container spacing={{ xs: 1, md: 2 }} 
                    columns={{ xs: 4, sm: 12, md: 12, lg: openSubMenu ? 6 : 12, xl: 12 }} 
                    justifyContent="center" 
                    alignItems="center"
                >
                    <Grid item xs={2} sm={4} md={openSubMenu ? 6 : 4} lg={2} xl={4}>
                        <ProgressCard
                            value={energy || "-"}
                            fontSize={16}
                            valueFontSize={15}
                            maxValue={maxEnergy}
                            label="Potência"
                            unit={"kW"}
                            background="#F9F9F9"
                            textColor="#000000"
                            width={220}
                            boldLabel={true}
                            flexWrap={openSubMenu}
                            isPercentValue={false}
                            intervals={{min: {value: maxEnergy * 0.33, color: "#FF4500"}, mid: {value: maxEnergy * 0.66, color: "#FFD700"}, max: {value: maxEnergy * 1.1, color: "#a4f657"}}}
                        />
                    </Grid>
                    <Grid item xs={2} sm={4} md={openSubMenu ? 6 : 4} lg={2} xl={4}>
                        <ProgressCard
                            value={irradiance || "-"}
                            fontSize={16}
                            valueFontSize={15}
                            maxWidthFont={openSubMenu ? {xs: 40, sm: 100} : {xs: 80, sm: 100}}
                            maxValue={maxIrradiance}
                            label="Irradiância"
                            unit={"W/m²"}
                            background="#F9F9F9"
                            textColor="#000000"
                            width={220}
                            boldLabel={true}
                            flexWrap={openSubMenu}
                            isPercentValue={false}
                            intervals={{min: {value:528, color: "#FF4500"}, mid: {value: 1056, color: "#FFD700"}, max: {value: 1600, color: "#a4f657"}}}
                        />
                    </Grid>
                    {meteorologicalStation && meteorologicalStation.length > 0 &&
                        meteorologicalStation.map((station) => (
                            <Grid item xs={2} sm={4} md={openSubMenu ? 6 : 4} lg={2} xl={4} key={station.id}>
                                <ProgressCard
                                    value={isNaN(station.value) ? "-" : truncateNumber(station.value, 2)}
                                    fontSize={16}
                                    valueFontSize={15}
                                    maxWidthFont={openSubMenu ? {xs: 40, sm: 90} : {xs: 80, sm: 100}}
                                    maxValue={station.maxValue}
                                    unit={station.unit}
                                    label={station.name}
                                    background="#F9F9F9"
                                    textColor="#000000"
                                    width={220}
                                    boldLabel={true}
                                    flexWrap={openSubMenu}
                                    isPercentValue={false}
                                    intervals={station.barInterval}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </ACard>
    );

}

export default InstantData;